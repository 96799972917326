(function photoBlock() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'photo-block';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  $(() => {
    const $document = GlobalSite.checkDependency('GlobalSite.$document');
    const $photoBlockContainer = $('.photo-block');
    const $photoBlockTextarea = $('.photo-block_textblock');
    const $textBlockMarginTop = '2.5rem';

    const setPhotoBlockSize = () => {
      $photoBlockContainer.each((i, elem) => {
        const $elem = $(elem);
        const textBlockHeight = $elem.find($photoBlockTextarea).outerHeight();
        const totalBlockHeight = `calc(${textBlockHeight}px + ${$textBlockMarginTop})`;

        $elem.css('min-height', totalBlockHeight);
      });
    };

    $document.on('load resize', () => {
      setPhotoBlockSize();
    });
  });
})();
