(function documentToDownload() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'document-to-download';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  const ReadMore = GlobalSite.checkDependency('GlobalSite.readMore');
  const readMoreInstance = new ReadMore();
  const compSelector = GlobalSite.checkDependency('GlobalSite.getSelectorForComp')(
    'document-to-download',
  );
  const getCrDataForTable = GlobalSite.checkDependency('GlobalSite.getCrDataForTable');
  const getCrDataForTableDTS = GlobalSite.checkDependency('GlobalSite.getCrDataForTableDTS');
  const fippenConfig = {
    API_URL: '/fr/credit.fipenDoc.json',
    fipenPP: {
      pdfObject: window?.fipenDocs[0] || undefined,
    },
    fipenCR: {
      pdfObject: window?.fipenDocs[0] || undefined,
    },
    undefined: {
      pdfObject: undefined,
    },
  };
  document.cookie = 'axes=fr|PC|world|prof|PC|b84a28bce03d4eb59363e7bf9181c600|;path=/;';

  const createForm = ($root) => {
    $root.find('form').remove();
    const formTemplate = `<form action="${fippenConfig.API_URL}" target="_blank" method="POST"> 
      <input type="hidden" name="fipenData" value="">
    </form>`;
    $root.append(formTemplate);
    return $root.find('form');
  };

  const addFormulasValues = async (AEMFIPENOBJECT) => {
    const AEMFIPENOBJECT_CLONE = AEMFIPENOBJECT;
    const oldAEMFipenModelArray = AEMFIPENOBJECT.model;
    const crData = await getCrDataForTable();
    const crDataDTS = await getCrDataForTableDTS();
    const newAEMFipenModelArray = [
      {
        label: 'cma_mens_taux_0-3',
        key: '0008',
        value: `${crData[0]?.tnc1}`,
      },
      {
        label: 'cma_mens_taux_4-5',
        key: '0009',
        value: `${crData[1]?.tnc1}`,
      },
      {
        label: 'cma_mens_taux_6-10',
        key: '00010',
        value: `${crData[2]?.tnc1}`,
      },
      {
        label: 'cma_mens_taux_11-20',
        key: '00011',
        value: `${crData[3]?.tnc1}`,
      },
      {
        label: 'cma_mens_taux_21-36 ',
        key: '00012',
        value: `${crData[4]?.tnc1}`,
      },
      {
        label: 'c_smart_taux_3',
        key: '00014',
        value: `${crDataDTS[0]?.tnc1}`,
      },
      {
        label: 'c_smart_taux_5',
        key: '00015',
        value: `${crDataDTS[1]?.tnc1}`,
      },
      {
        label: 'c_smart_taux_10',
        key: '00016',
        value: `${crDataDTS[2]?.tnc1}`,
      },
      {
        label: 'c_smart_taux_20',
        key: '00017',
        value: `${crDataDTS[3]?.tnc1}`,
      },
      {
        label: 'cma_mens_taeg_0-3',
        key: '00018',
        value: `${crData[0]?.taeg1}`,
      },
      {
        label: 'cma_mens_taeg_4-5',
        key: '00019',
        value: `${crData[1]?.taeg1}`,
      },
      {
        label: 'cma_mens_taeg_6-10',
        key: '00020',
        value: `${crData[2]?.taeg1}`,
      },
      {
        label: 'cma_mens_taeg_11-20',
        key: '00021',
        value: `${crData[3]?.taeg1}`,
      },
      {
        label: 'cma_mens_taeg_21-36 ',
        key: '00022',
        value: `${crData[4]?.taeg1}`,
      },
      {
        label: 'c_smart_taeg_3',
        key: '00023',
        value: `${crDataDTS[0]?.taeg1}`,
      },
      {
        label: 'c_smart_taeg_5',
        key: '00024',
        value: `${crDataDTS[1]?.taeg1}`,
      },
      {
        label: 'c_smart_taeg_10',
        key: '00025',
        value: `${crDataDTS[2]?.taeg1}`,
      },
      {
        label: 'c_smart_taeg_20',
        key: '00026',
        value: `${crDataDTS[3]?.taeg1}`,
      },
    ];

    const updatedAEMFipenModelArray = oldAEMFipenModelArray.map((obj1) => {
      const newObj = newAEMFipenModelArray.find((obj2) => obj2.key === obj1.key);
      return newObj ? { ...obj1, ...newObj } : obj1;
    });
    AEMFIPENOBJECT_CLONE.model = updatedAEMFipenModelArray;
    return AEMFIPENOBJECT_CLONE;
  };
  $(() => {
    readMoreInstance.domHandler();

    $(`${compSelector} a#fipenPP, ${compSelector} a#fipenCR`).on('click', async (e) => {
      e.preventDefault();
      let $target = $(e.target);
      if ($target[0].localName !== 'a') {
        $target = $target.parent();
      }
      const $parent = $target.parent();
      const targetId = $target.attr('id');

      if (!fippenConfig[targetId]?.pdfObject) {
        Log.error({ error: true, message: 'AEMFIPENOBJECT global variable not set from AEM' });
        return;
      }

      const $form = createForm($parent);
      const $input = $form.find('input[name="fipenData"]');
      $input.attr(
        'value',
        JSON.stringify(
          targetId === 'fipenCR'
            ? await addFormulasValues(fippenConfig[targetId]?.pdfObject)
            : fippenConfig[targetId]?.pdfObject,
        ),
      );
      $form.submit();
    });
  });
})();
