(function universLink() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'univers-link';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  $(() => {
    const $linkListTriggerButton = $('.univers-link__selector');
    const $linkList = $('.univers-link__link-list');

    const toggleMobileLinklist = ($this) => {
      $this.siblings($linkList).toggleClass('hide');
      const isExpanded = $this.siblings($linkList).hasClass('hide');
      $this.attr('aria-expanded', !isExpanded);

      $this.children('i').toggleClass('i-chevron-down i-chevron-up');
    };

    $linkListTriggerButton.on('click', function () {
      toggleMobileLinklist($(this));
    });
  });
})();
