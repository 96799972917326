

(function caroussel () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "caroussel";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
      $('.caroussel__container').slick({
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 0,
        infinite: false,
        dots: false,
        arrows: false,
        prevArrow: `<button class="slick-prev" type="button">
        <i class="i-chevron-left slider-arrow" aria-hidden="true"></i>
        <span class="slick-sr-only">Slide précédente</span>
        </button>`,
        nextArrow: `<button class="slick-next" type="button">
        <i class="i-chevron-right slider-arrow" aria-hidden="true"></i>
        <span class="slick-sr-only">Slide suivante</span>
        </button>`,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true,
              swipe: true,
              infinite: false,
              dots: true,
              arrows: true
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
              dots: true,
              arrows: true
            },
          },  
        ],
        customPaging: (slider, i) =>
          $(
            `<button type="button">
            <span class="slick-dot-icon" aria-hidden="true" aria-label="Choisir une slide"></span>
            <span class="slick-sr-only">Aller vers la slide ${i + 1}"</span> 
          </button>`,
          ),
      });
    })

    })()