(function button() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'button';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  const getUrlParamsString = GlobalSite.checkDependency('GlobalSite.getUrlParamsString');
  $(() => {
    const $button = $('._button').find('a');

    $button.on('click', (e) => {
      e.preventDefault();
      const $this = $(e.target);
      const href = $this.attr('href');
      const redirectionUrl = `${href}${getUrlParamsString()}`;
      window.location.assign(redirectionUrl);
    });
  });
})();
