(function creditSimulatorDesktop() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'credit-simulator-desktop';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  $(() => {
    const allInputs = $('.credit-simulator-dt');

    allInputs.each(function () {
      const $amountInput = $(this).find('#credit-simulator-value--slider');
      const $startSimulatorButton = $(this).find('#credit-simulator-button--slider');
      const $amountMessage = $(this).find('.credit-simulator-dt__amount__text');
      const $amountErrorMessage = $(this).find('.credit-simulator-dt__amount__text.error');
      const $accessibleAmountMessage = $(this).find('.accessible-message');
      const $accessibleAmountErrorMessage = $(this).find('.accessible-message-error');

      const creditSimuUi = GlobalSite.checkDependency('GlobalSite.creditSimulatorUi')(
        $amountInput,
        $startSimulatorButton,
        $amountMessage,
        $amountErrorMessage,
        $accessibleAmountMessage,
        $accessibleAmountErrorMessage,
      );

      creditSimuUi.domHandler();
    });
  });
})();
