(function goToTop() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'go-to-top';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  $(() => {
    const $gototop = $('.go-to-top__button');
    const $body = GlobalSite.checkDependency('GlobalSite.$body') || $('body');

    $gototop.on('click tap', () => {
      Foundation.SmoothScroll.scrollToLoc($body, {
        animationEasing: 'swing',
        threshold: 0,
        offset: 0,
      });

      $body.attr('tabindex', 1).focus().removeAttr('tabindex');
    });
  });
})();
