(function header() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'header';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const redirection = GlobalSite.checkDependency('GlobalSite.getFaqRedirection');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }

  function headerLogic(inbentaApi, searchEngineUi) {
    const $document = GlobalSite.checkDependency('GlobalSite.$document');
    const getClient = GlobalSite.checkDependency('GlobalSite.getClient');
    const $mainNavItems = $('.navtab .mainnav_item');
    const $navPanelClosebuttons = $('.navpanel .close');
    const $navMobileItem = $('.navmobile_item a');
    const $navMobileBurgerIcon = $('.mobileNav a');
    const $navMobileLoginIcon = $('.mobileLogin a');
    const $navMobileTreeContainer = $('.navmobile_tree_container');
    const $navMobileLink = $('.mobileNav > a');
    const $navMobileLoginContainer = $('.navmobile_login_container');
    const $navMobileLoginLink = $('.mobileLogin > a');
    const keboardKeys = {
      escape: { name: 'Escape', code: 27 },
    };
    const $searchInput = $('#mainsearch-input');
    const $searchInputButton = $('#mainsearch-button');
    const $searchInputMobile = $('#mainsearch-input__mobile');
    const $searchInputButtonMobile = $('#mainsearch-button__mobile');

    let searchInputValue = '';

    async function initInbentaAutocomplete() {
      if (!inbentaApi) return;
      const inbentaAuth = await inbentaApi.auth();
      inbentaAuth &&
        searchEngineUi(
          [$searchInput, $('undefined'), $('undefined'), $('.inbenta-autocomplete-header')],
          'moteur',
          undefined,
          true,
        );
    }

    function showNavigationOverlay() {
      $('.navigation_overlay').removeClass('hide');
    }

    function hideNavigationOverlay() {
      $('.navigation_overlay').addClass('hide');
    }

    function closeDesktopSubMenu() {
      $('.navpanel').addClass('hide');
      $mainNavItems.attr('aria-expanded', 'false');
      hideNavigationOverlay();
    }

    function openCloseDesktopSubMenu($this) {
      if ($this.attr('aria-expanded') === 'true') {
        closeDesktopSubMenu();
        return;
      }
      const index = $this.parent().data('tab');
      $('.navpanel').addClass('hide');
      $mainNavItems.attr('aria-expanded', 'false');
      $(`.navpanel-${index}`).removeClass('hide');
      $(`.navtab-${index} .mainnav_item`).attr('aria-expanded', 'true');
      showNavigationOverlay();

      if (index === 99) {
        initInbentaAutocomplete();
      }
    }

    function closeOpenMobileMenu() {
      $navMobileTreeContainer.toggleClass('hide');

      if ($navMobileTreeContainer.hasClass('hide')) {
        $navMobileLink.attr('aria-expanded', 'false');
        hideNavigationOverlay();
      } else {
        $navMobileLink.attr('aria-expanded', 'true');
        showNavigationOverlay();
      }

      $navMobileLoginContainer.addClass('hide').attr('aria-expanded', 'false');
      $navMobileLoginLink.attr('aria-expanded', 'false');
    }

    function closeOpenMobileLoginMenu() {
      $navMobileLoginContainer.toggleClass('hide');

      if ($navMobileLoginContainer.hasClass('hide')) {
        $navMobileLoginLink.attr('aria-expanded', 'false');
        hideNavigationOverlay();
      } else {
        $navMobileLoginLink.attr('aria-expanded', 'true');
        showNavigationOverlay();
      }

      $navMobileTreeContainer.addClass('hide');
      $navMobileLink.attr('aria-expanded', 'false');
    }

    function closeMobileSubMenu() {
      $('.navmobile_tab > a').removeClass('active').attr('aria-expanded', 'false');
      $('.navmobile_panel').addClass('hide');
      $('.navmobile_tab').removeClass('hide');
    }

    function openMobileSubMenu($this) {
      const index = $this.parent().data('tab');
      $('.navmobile_tab, .navmobile_panel').addClass('hide');
      $(`.navmobile_tab-fix, .navmobile_tab-${index}, .navmobile_panel-${index}`).removeClass(
        'hide',
      );

      $(`.navmobile_tab-${index} > a`).addClass('active').attr('aria-expanded', 'true');
    }

    function updateSearchTarget(value) {
      searchInputValue = value;

      $searchInput.val(searchInputValue);
      $searchInputMobile.val(searchInputValue);

      $searchInputButton.toggleClass('active', searchInputValue.length > 0);
      $searchInputButtonMobile.toggleClass('active', searchInputValue.length > 0);
    }

    function sendSearchRequest() {
      if (searchInputValue === '') return;
      const urlToRedirect = {
        cetelem: {
          params: `?q=${searchInputValue}&ac=&uq=&lc=`,
        },
        cofinoga: {
          params: `?q=${searchInputValue}&ac=&uq=&lc=`,
        }
      };
      const client = urlToRedirect[getClient()];
      window.location.href = `${redirection?.search}${client?.params}`;
    }

    $mainNavItems
      .on('click tap', function (e) {
        if ($(this).attr('href') === '#' || $(this).attr('href') === '') {
          e.preventDefault();
        }
        openCloseDesktopSubMenu($(this));
      })
      .on('keydown', (e) => {
        if (e.keyCode === 32) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          $(e.target).trigger('click');
        }
      });

    $navPanelClosebuttons.on('click', () => {
      closeDesktopSubMenu();
    });

    $navMobileItem.on('click', function () {
      if ($navMobileItem.hasClass('active')) {
        closeMobileSubMenu();
        return;
      }
      openMobileSubMenu($(this));
    });

    $navMobileBurgerIcon.on('click', () => {
      closeOpenMobileMenu();
    });

    $navMobileLoginIcon.on('click', () => {
      closeOpenMobileLoginMenu();
    });

    $document.on('keydown', (e) => {
      if (e.key === keboardKeys.escape.name) {
        $navPanelClosebuttons.trigger('click');
      }
    });

    $searchInput.on('input', (e) => {
      updateSearchTarget(e.target.value);
    });
    $searchInputButton.on('click', (e) => {
      e.preventDefault();
      sendSearchRequest();
    });
    $searchInputMobile.on('input', (e) => {
      updateSearchTarget(e.target.value);
    });
    $searchInputButtonMobile.on('click', (e) => {
      e.preventDefault();
      sendSearchRequest();
    });
  }

  $(async () => {
    try {
      const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
      const searchEngineUi = GlobalSite.checkDependency('GlobalSite.searchEngineUi');
      headerLogic(inbentaApi, searchEngineUi);
    } catch (e) {
      const inbentaApi = undefined;
      const searchEngineUi = undefined;
      headerLogic(inbentaApi, searchEngineUi);
      Log.error(e);
    }
  });
})();
