(function sliderAvisVerifies() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'slider-avis-verifies';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  const getCustomReviews = GlobalSite.checkDependency('GlobalSite.getCustomReviews');
  const getGlobalRateAndReview = GlobalSite.checkDependency('GlobalSite.getGlobalRateAndReview');
  const getPercentRate = GlobalSite.checkDependency('GlobalSite.getPercentRate');
  const fomatDate = GlobalSite.checkDependency('GlobalSite.fomatDate');

  const generateSliderTemplate = (items) => {
    let $items = '';
    items.map((item) => {
      $items =
        `${$items}<div class="slider-avis-verifies__slider-container__card"> <i class = "i-quotation-mark-open text-quote" ></i><p>${item.review}</p>` +
        `<div class="author"><span class="author__name">${item.firstname}</span>` +
        `<div class="author__grade avisverifies__star-container"><span class="avisverifies__star-container--rate" style="width:${getPercentRate(
          item.rate,
        )}"></span></div>` +
        `</div><span class="request">Demande réalisée le : <span>${fomatDate(
          item.order_date,
        )}</span></span><br />` +
        `<span class="publish">Publié le : <span>${fomatDate(
          item.publish_date,
        )}</span></span></div>`;
      return $items;
    });
    return $items;
  };

  $(async () => {
    const customReviews = await getCustomReviews();
    const { globalRate, globalReview } = await getGlobalRateAndReview();
    const $bloc = $('.slider-avis-verifies__slider-container');
    const $rateBlock = $('.avisverifies__star-container--rate');
    const $gradeBlock = $('.slider-avis-verifies__grade--value span');
    const $reviewBlock = $('.slider-avis-verifies__review--value');

    $gradeBlock.html(Number(globalRate).toPrecision(2).replace('.', ','));
    $rateBlock.css('width', getPercentRate(globalRate));
    $bloc.append(generateSliderTemplate(customReviews));
    $reviewBlock.html(globalReview);

    $('.slider-avis-verifies__slider-container').slick({
      autoplay: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: false,
      dots: true,
      arrows: true,
      prevArrow: `<button class="slick-prev" type="button">
      <i class="i-arrow-left-circle slider-arrow" aria-hidden="true"></i>
      <span class="slick-sr-only">Slide précédente</span>
      </button>`,
      nextArrow: `<button class="slick-next" type="button">
      <i class="i-arrow-right-circle slider-arrow" aria-hidden="true"></i>
      <span class="slick-sr-only">Slide suivante</span>
      </button>`,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: true,
          },
        },
      ],
      customPaging: (slider, i) =>
        $(
          `<button type="button">
          <span class="slick-dot-icon" aria-hidden="true" aria-label="Choisir une slide"></span>
          <span class="slick-sr-only">Aller vers la slide ${i + 1}"</span> 
        </button>`,
        ),
    });
  });
})();
