

(function modalValidation () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "modal-validation";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
      if($("a[data-open^=modal-validation][data-load]").length === 1){
        const modalId = $("a[data-open^=modal-validation][data-load]").attr('data-open');
        $('#'+modalId).trigger("open");
      }
    })

    })()