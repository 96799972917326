(function creditSimulator() {
  const isCompExists = GlobalSite.checkDependency('GlobalSite.isCompExists');
  const compName = 'credit-simulator';
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory(`${compName}.js`);

  if (!isCompExists(compName, Log)) {
    return;
  }
  $(() => {
    const $creditSimulatorFrame = $('.credit-simulator .credit-simulator__frame');
    const $amountInput = $('.credit-simulator #credit-simulator-value--sidebar');
    const $startSimulatorButton = $('.credit-simulator #credit-simulator-button--sidebar');
    const $amountMessage = $('.credit-simulator .credit-simulator__amount__text');
    const $amountErrorMessage = $('.credit-simulator .credit-simulator__amount__text.error');
    const $accessibleAmountMessage = $('.credit-simulator .accessible-message');
    const $accessibleAmountErrorMessage = $('.credit-simulator .accessible-message-error');
    const $mobileAccessButton = $('.credit-simulator .credit-simulator__mobileaccess__button');
    const $creditSimulatorOverlay = $('.credit-simulator .credit-simulator__overlay');

    const creditSimuUi = GlobalSite.checkDependency('GlobalSite.creditSimulatorUi')(
      $amountInput,
      $startSimulatorButton,
      $amountMessage,
      $amountErrorMessage,
      $accessibleAmountMessage,
      $accessibleAmountErrorMessage,
    );
    creditSimuUi.domHandler();

    let isMobileSimulatorOpen = false;

    const toggleMobileSimulator = (isOpen) => {
      isMobileSimulatorOpen = !isOpen;
      $creditSimulatorFrame.toggleClass('hide', !isMobileSimulatorOpen);
      $creditSimulatorOverlay.toggleClass('hide', !isMobileSimulatorOpen);
    };

    $mobileAccessButton.on('click', () => {
      toggleMobileSimulator(isMobileSimulatorOpen);
    });
  });
})();
